<template>
  <div>
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> User Details</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">An email will be sent to the user to finalise their account creation.</p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup id="name" v-model="value.name" :disabled="disabled" :error="error['name']" label="Name" type="text" />
        <FormGroup id="email" v-model="value.email" :disabled="disabled" :error="error['email']" label="Email" type="text" />
        <FormGroup
          id="roleId"
          v-model="value.roleId"
          :disabled="disabled"
          :error="error['roleId']"
          label="Role"
          type="select2"
          :options="roleOptions"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '../FormGroup';

export default {
  name: 'CompanyUserForm',
  components: {
    FormGroup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      roles: 'role/roles'
    }),
    roleOptions() {
      const roles = [...this.roles];

      roles.sort((a, b) => a.name.localeCompare(b.name));

      return roles.map(role => ({
        value: role._id,
        label: role.name
      }));
    }
  },
  async mounted() {
    if (!this.roles.length) await this.getRoles();
  },
  methods: {
    ...mapActions({
      getRoles: 'role/list'
    })
  }
};
</script>
