<template>
  <div data-cy="user-edit-form">
    <TabTitle class="mb-4" icon="fa-user-gear">
      <template>Edit User</template>
      <template #sub-title>Edit and update the user details.</template>
      <template #actions>
        <router-link class="btn btn-link" :to="{ path: `/company/users` }"><i class="fa fa-arrow-left mr-1"></i></router-link>
      </template>
    </TabTitle>
    <div class="block-content">
      <div v-show="errorAction.updateUser" class="alert alert-danger">{{ errorAction.updateUser }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <div v-if="loadingAction.getUser || !Object.keys(form).length" class="d-flex justify-content-center mb-5">
            <div class="text-center">
              <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
                <span class="sr-only">Loading user details...</span>
              </div>
              <div class="font-w600">Loading user details...</div>
            </div>
          </div>
          <div v-else>
            <CompanyUserForm v-model="form" :error="validationErrors" :disabled="loadingAction.update" />
            <div class="text-right pb-4">
              <button type="submit" class="btn btn-primary" :disabled="loadingAction.updateUser" @click="onEditUser">
                <span v-if="loadingAction.updateUser"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
                <span v-else><i class="fa fa-check-circle mr-1"></i> Update User</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import CompanyUserForm from '@/components/forms/CompanyUserForm';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'CompanyUsersEdit',
  components: {
    CompanyUserForm,
    TabTitle
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'user/loadingAction',
      errorAction: 'user/errorAction',
      validationErrors: 'user/validationErrors'
    })
  },
  async mounted() {
    const [user] = await Promise.all([this.getUser({ id: this.$route.params.userId }), this.getRoles()]);
    this.form = user;
  },
  methods: {
    ...mapActions({
      getUser: 'user/get',
      updateUser: 'user/update',
      getRoles: 'role/list'
    }),
    async onEditUser() {
      const updateUser = await this.updateUser({ id: this.$route.params.userId, data: this.form });

      if (updateUser?.userSub) {
        this.$toasted.success('User updated successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-users-list', params: { id: this.$auth.companyId } });
      } else {
        this.$toasted.error('User was not updated successfully.', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
